var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3MmI1b745AUlcxlzFLhxV"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';
import { NEXT_PUBLIC_SENTRY_DSN } from '@/config/env';

const SENTRY_DSN = NEXT_PUBLIC_SENTRY_DSN ?? '';

if (process.env.NODE_ENV === 'production' && SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
            new Sentry.Replay({
                maskAllText: true,
                blockAllMedia: true,
            }),
            new Sentry.BrowserTracing(),
        ],
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 0.7,
        replaysSessionSampleRate: 0.7,
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}
